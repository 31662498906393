import request from '@/utils/request'

// 查询水情参报事件
export function baojinglist(query) {
  return request({
    url: '/daping/warningEventList',
    method: 'get',
    params: query
  })
}
// 查询水情参报事件
export function sjggbaojinglist(query) {
  return request({
    url: '/shuijiagaige/daping/warningEventList',
    method: 'get',
    params: query
  })
}
// 短信推送
export function notification(query) {
  return request({
    url: '/iot/alert/notification',
    method: 'get',
    params: query
  })
}
// 查询部门下拉树结构
export function deptTreeSelect() {
  return request({
    url: '/system/user/deptTree',
    method: 'get'
  })
}

// 查询用户列表
export function listUser(query) {
  return request({
    url: '/system/user/list',
    method: 'get',
    params: query
  })
}