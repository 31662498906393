<template>
	<el-dialog :visible.sync="bjsj" width="0px" append-to-body class="el-dialog-bjsj" :before-close="handleClickbjsj">

		<div>
			<div class="x-tc-page-bg">
				<div class="x-tc-title douyuFont" v-if="this.yujing == 2">
					事件报警
				</div>
				<div class="x-tc-title douyuFont" v-if="this.yujing == 3">
					事件预警
				</div>
				<div class="x-tc-title douyuFont" v-if="this.yujing == 4">
					事件报警
				</div>
				<div class="x-tc-title douyuFont" v-if="this.yujing == 5">
					事件预报
				</div>
				<div class="x-close" @click="closeClick"></div>
				<div class="x-tc-cnt">
					<div class="baojingleixingshaixuan">
						<span class="xuanqian">日期：</span>
						<el-date-picker v-model="xuanzeshijian" type="date" placeholder="请选择日期"
							value-format="yyyy-MM-dd" @change="dianjiriqi()"  prefix-icon="''" :editable="false" clearable>
						</el-date-picker>
						<span class="xuanqian">类型：</span>
						<el-select v-model="xuanzeleixing" multiple collapse-tags placeholder="请选择类型" style="float: left;">
							<el-option v-for="item in leixinglist" :key="item.dictValue" :label="item.dictLabel"
								:value="parseInt(item.dictValue)">
							</el-option>
						</el-select>

						<span class="xuanqian">等级：</span>
						<div class="zhaixuan">
							<el-select v-model="dengjixuanze" multiple collapse-tags placeholder="请选择等级" style="float: left;">
								<el-option v-for="item in dengjilist" :key="item.value" :label="item.label"
									:value="parseInt(item.value)">
								</el-option>
							</el-select>
						</div>

						<span class="xuanqian">状态：</span>
						<div class="zhaixuan">
							<el-select v-model="zhuangtaide" placeholder="请选择状态" clearable style="float: left;">
								<el-option v-for="item in zhuangtailist" :key="item.value" :label="item.label"
									:value="item.value" />
								</el-option>
							</el-select>
						</div>
						<div class="chaxunanniu" @click="getbaojinglist()"><span
								style="margin-left: 20px;cursor: pointer;">查询</span>
						</div>



					</div>
					<div class="biaoti">
						<span class="bt_span" style="width:80px;">序号</span>
						<span class="bt_span" style="width:130px;">类型</span>
					<!-- 	<span class="bt_span" style="width:170px;" v-if="this.yujing == 2">报警时间</span>
						<span class="bt_span" style="width:170px;" v-if="this.yujing == 3">预警时间</span>
						<span class="bt_span" style="width:170px;" v-if="this.yujing == 4">时间</span>
						<span class="bt_span" style="width:170px;" v-if="this.yujing == 5">预报时间</span> -->
						<span class="bt_span" style="width:100px;">日期</span>
						<span class="bt_span" style="width:70px;">时间</span>
						<span class="bt_span" style="width:130px;">等级</span>
						<span class="bt_span" style="width:220px;">内容</span>
						<span class="bt_span" style="width:110px;">状态</span>
						<span class="bt_span" style="width:100px;">操作</span>
					</div>
					<img src="~@/assets/common/hengtiao.png"
						style="width: 960px;height: 8px;float: left; margin-left: 72.5px;" />
					<div class="xia" v-loading="loading">
						<template v-if="this.total != 0">
							<div v-for="(item, index) of liebiaoneirong" :key="index">
								<div class="neirong" :class="index % 2 == 0 ? 'beijingwuse' : 'beijinglanse'">
									<span class="nr_span" style="width:80px;">{{ index + 1 + (currentPage - 1) * 20
									}}</span>
									<span class="nr_span" style="width:130px;" v-if="item.eventType != 0">{{
										event_type[item.eventType] }}</span>
									<span class="nr_span" style="width:130px;" v-if="item.eventType == 0">{{ item.alertName
									}}</span>
									<!-- <span class="nr_span" style="width:170px;">{{ item.createTime }}</span> -->
									<span class="nr_span" style="width:100px;">{{ item.alertDate }}</span>
									<span class="nr_span" style="width:70px;">{{ item.alertTime }}</span>
									<span class="nr_span" style="width:130px;color: #ff0000;"
										v-if="item.eventLevel == 1">1级</span>
									<span class="nr_span" style="width:130px;color: #ff7600;"
										v-if="item.eventLevel == 2">2级</span>
									<span class="nr_span" style="width:130px;color: #ffff00;"
										v-if="item.eventLevel == 3">3级</span>
									<el-tooltip :content="item.message" placement="top" effect="light">
										<span class="nr_span" style="width:220px;">{{ item.message }}</span>
									</el-tooltip>

									<!-- <span class="nr_span" style="width:110px;color: #91ecff;" v-if="item.status == 1"
										@click="getyidu(item.alertId, index)">未读</span> -->
									<span class="nr_span" style="width:110px;color: #91ecff;"
										v-if="item.status == 1">未读</span>

									<span class="nr_span" style="width:110px;" v-if="item.status != 1">已读</span>
									<span class="nr_span cursor" style="width:100px;color: #91ecff;"
										@click="handleClickcktp(item.alertId, item.message, item.fileUrl, item.alertType, index, item)">查看</span>
								</div>
							</div>
						</template>
						<template v-if="this.total == 0">
							<span style="
								color: #218fce;
								width: 100%;
								height: 100%;
								
								display: flex;
								justify-content: center;
								align-items: center;
								flex-direction: column;
								font-size: 17px;
								text-align: center
								">暂无数据</span>
						</template>
					</div>
					<div class="fanye" v-if="this.total <= 20">
					</div>
					<div class="fanye" v-if="this.total > 20">
						<div class="block">
							<el-pagination background layout="prev, pager, next" :total="total" :page-size="20"
								:current-page.sync="currentPage" @current-change="getbaojinglist">
							</el-pagination>
						</div>
					</div>

				</div>
			</div>
		</div>
		<chakantupian ref="dialog" v-if="cktp" :cktpId="cktpId" :cktpwen="cktpwen" :cktpurl="cktpurl" :leixing="leixing"
			:zongitem="zongitem" @on-point="cktp = false">
		</chakantupian>
	</el-dialog>
</template>

<script>
	import {
		yidu
	} from "@/api/dapingshuiqingcanbao/yidu";
	import {
		getDictData
	} from "@/utils/dict";
	import {
		baojinglist
	} from "@/api/dapingshuiqingcanbao/baojinglist";
	import {
		gaojingshijianlist
	} from "@/api/zongheyutu/gaojingshijianlist";
	import {
		zidianliebia
	} from "@/api/dapingshuiqingcanbao/zidianliebia";
	import chakantupian from "@/views/ShuNao/sqcbtanchuang/chakantupian";
	export default {
		name: "index",
		components: {

			chakantupian,
		},
		props: {
			chuanedshuikuId: {
				type: Number,
				default: undefined
			},
			bjsjId: {
				type: Number,
				default: 0
			},
			dengji: {
				type: Number,
				default: null
			},
			shebeiId: {
				type: Object
			},
			chuandengjixuanze: {
				type: Array,
				// default: function() {
				//   return [1];
				// },
				default: function() {
					return [];
				},
			},

			getshuiqingcanbaolistone: {
				type: Function,
				default: () => {
					return Function;
				},
			},
			guanquId: {
				type: Number,
			},
		},
		data() {
			return {
				zhuangtailist: [{
					value: 1,
					label: '未读'
				}, {
					value: 2,
					label: '已读'
				}],
				zhuangtaide: 1,

				loading: true,
				// 类型字典
				event_type: [],
				xuanzeleixing: [],
				xuanzeshijian: '',
				dengjixuanze: this.chuandengjixuanze,

				cktp: false,
				cktpId: null,
				cktpurl: null,
				cktpwen: null,
				leixing: null,

				// 弹出层标题
				bjsj: true,
				yujing: this.bjsjId,
				guanquIdtwo: this.guanquId,
				currentPage: 1,
				total: -1,
				liebiaoneirong: [],
				dijige: null,
				zongitem: {},
				number: null,
				shebeisn: "",
				leixinglist: [],
				dengjilist: [{
					value: '1',
					label: '1级'
				}, {
					value: '2',
					label: '2级'
				}, {
					value: '3',
					label: '3级'
				}],
				chuanleixing: "",
				chuandengji: "",
			}
		},

		created() {

		},

		mounted() {
			console.log("chuanedshuikuId" + this.chuanedshuikuId)
			if (this.yujing == 4) {

				console.log(this.shebeiId)
				this.shebeisn = this.shebeiId.serialNumber
				console.log(this.shebeisn)
				// this.number = this.shebeiId.split('').filter(c => !isNaN(c)).join('');
				// console.log("+++********++" + this.number)
			}
			if (this.yujing == 5) {
				this.dengjixuanze = [];
			}
			this.getzidianliebia()

			getDictData("event_type").then((val) => {
				this.event_type = val;
				console.log(this.event_type)
			});
			this.getbaojinglist()


		},
		beforeDestroy() {

		},
		methods: {
			dianjiriqi() {
				console.log(this.xuanzeshijian)
			},
			emitLoading(type) {
				this.loading = type;
				this.$emit('on-loading', type)
				console.log(this.loading)
			},

			childMethod() {
				this.getshuiqingcanbaolistone();
				console.log('调用父组件方法')
			},
			getzidianliebia() {
				if (this.yujing == 2 || this.yujing == 4) {
					zidianliebia({
						type: "warning"
					}).then(response => {
						this.leixinglist = response.rows;
						this.emitLoading(false);
						console.log(this.leixinglist)
					})
				}
				if (this.yujing == 3) {
					zidianliebia({
						type: "danger"
					}).then(response => {
						this.leixinglist = response.rows;
						this.emitLoading(false);
						console.log(this.leixinglist)
					})
				}
				if (this.yujing == 5) {
					zidianliebia({
						type: "primary"
					}).then(response => {
						this.leixinglist = response.rows;
						this.emitLoading(false);
						console.log(this.leixinglist)
					})
				}
			},

			getyidu(ID, index) {
				this.dijige = index
				yidu({
					alertId: ID,
					status: 2
				}).then(response => {
					console.log("已读成功")
					this.liebiaoneirong[this.dijige].status = 2
					console.log(this.liebiaoneirong[this.dijige].status)
					this.childMethod()

				})
			},

			getbaojinglist() {
				this.emitLoading(true);
				this.chuanleixing = this.xuanzeleixing.join(',')
				this.chuandengji = this.dengjixuanze.join(',')
				console.log(this.chuanleixing)
				console.log(this.chuandengji)
				console.log("this.yujing")
				console.log(this.yujing)
				if (this.yujing == 2 || this.yujing == 3) {
					baojinglist({
						status: this.zhuangtaide,
						pageNum: this.currentPage,
						pageSize: 20,
						alertDate: this.xuanzeshijian,
						alertLevel: this.yujing, //告警级别（1=动态消息，2=报警事件，3=预警事件）
						fenleieventType: this.chuanleixing,
						fenleilevel: this.chuandengji,
						associatedReservoirId: this.chuanedshuikuId,
						associatedIrrigatedAreaId: this.guanquIdtwo
					}).then(response => {
						if (response.code == 200) {
							this.liebiaoneirong = response.rows;
							this.total = response.total
							this.emitLoading(false);
							console.log("成功")
						}
					})
				}
				if (this.yujing == 5) {
					baojinglist({
						status: this.zhuangtaide,
						pageNum: this.currentPage,
						pageSize: 20,
						alertLevel: 4, //告警级别（1=动态消息，2=报警事件，3=预警事件）
						alertDate: this.xuanzeshijian,
						fenleieventType: this.chuanleixing,
						fenleilevel: this.chuandengji,
						associatedReservoirId: this.chuanedshuikuId,
						associatedIrrigatedAreaId: this.guanquIdtwo
					}).then(response => {
						if (response.code == 200) {
							this.liebiaoneirong = response.rows;
							this.total = response.total
							this.emitLoading(false);
							console.log("成功")
						}
					})
				}
				if (this.yujing == 4) {

					baojinglist({
						status: this.zhuangtaide,
						pageNum: this.currentPage,
						pageSize: 20,
						serialNumber: this.shebeisn,
						fenleieventType: this.chuanleixing,
						alertDate: this.xuanzeshijian,
						fenleilevel: this.chuandengji,
					}).then(response => {
						if (response.code == 200) {
							this.liebiaoneirong = response.rows;
							this.total = response.total
							this.emitLoading(false);
							console.log("成功")
						}
					})
				}
			},
			handleClickcktp(data, wen, url, lei, index, item) {
				console.log("当前被点击的id" + data)
				this.cktpId = data;
				this.cktpId = Number(this.cktpId)
				this.cktpwen = wen;
				this.cktpurl = url;
				this.leixing = lei;
				this.leixing = Number(this.leixing);
				this.zongitem = item;
				this.cktp = true;
				this.getyidu(this.cktpId, index)
			},


			handleClickbjsj() {
				this.$emit('on-point')
			},

			closeClick() {
				this.$emit('on-point')
			},

		}
	}
</script>
<style lang="scss">
	.baojingleixingshaixuan .el-date-editor.el-input,
	.el-date-editor.el-input__inner {
		width: 115px;
		float: left;
	}

	.baojingleixingshaixuan .el-tag.el-tag--info {
		background-color: #84bfe19c;
		/* border-color: #e9e9eb; */
		color: #ffffff;
	}

	.baojingleixingshaixuan .el-tag {
		border-width: 0px;
	}

	.baojingleixingshaixuan ::placeholder {
		color: white !important;
	}

	.baojingleixingshaixuan .el-select .el-input .el-select__caret {
		color: #ffffff;
		font-size: 16px;
		/* margin-top: 12px; */
	}

	.baojingleixingshaixuan .el-select>.el-input {
		display: block;
		width: 165px;
	}
	.zhaixuan .el-select>.el-input {
		width: 135px !important;
	}

	.baojingleixingshaixuan .el-input__icon {
		// height: 30%;
		/* line-height: 6px; */
		line-height: 0px;
	}

	.baojingleixingshaixuan .el-input__inner {
		background-color: #1a6e9f;
		color: #fff;
		border: 0px solid #fff;
	}

	.baojingleixingshaixuan .el-input--prefix .el-input__inner {
		padding-left: 10px !important;
		height: 30px;
		/* margin-top: 4px; */
		color: #fff;
	}

	.baojingleixingshaixuan .el-input--suffix .el-input__inner {
		padding-right: 10px !important;
		height: 30px;
		color: #fff;
	}

	.baojingleixingshaixuan .el-input__inner {
		background-color: #1a6e9f;
		color: #fff;
		border: 0px solid #fff;
	}

	.baojingleixingshaixuan .el-input__inner {
		height: 37px;
		background-color: #1a6e9f;
		color: #fff;
		border: 0px solid #fff;
	}
</style>
<style scoped>
	.xuanqian {
		color: #fff;
		font-size: 17px;
		line-height: 32px;
		float: left;
		margin-left: 24px;
	}

	.chaxunanniu {
		color: #fff;
		margin-left: 20px;
		float: left;
		line-height: 32px;
		width: 72px;
		height: 32px;
		margin-top: 0px;
		border-radius: 10%;
		font-size: 16px;
		background-color: #1a6e9f;

	}

	.chaxunanniu:hover {
		color: #fff;
		margin-left: 20px;
		float: left;
		line-height: 32px;
		width: 72px;
		height: 32px;
		margin-top: 0px;
		border-radius: 10%;
		font-size: 16px;
		background-color: #218fce;

	}

	.beijingwuse {
		background-color: #0d3955;

	}

	.beijinglanse {
		background-color: #104d7b;

	}

	.biaoti {

		background-color: #104d7b;
		width: 940px;
		height: 61px;
		margin-top: 5px;
		margin-left: 72.5px;
		float: left;
		/* border: #fff solid 1px; */

	}

	.bt_span {
		float: left;
		color: #fff;
		margin-top: 0px;
		margin-left: 0px;
		/* width: 188px; */
		height: 61px;
		cursor: pointer;
		display: block;
		font-size: 18px;
		line-height: 66px;
		font-weight: bold;
		text-align: center;

	}

	.nr_span {
		float: left;
		color: #fff;
		margin-top: 0px;
		margin-left: 0px;
		/* width: 188px; */
		height: 54px;
		cursor: pointer;
		/* display: block; */
		font-size: 16px;
		line-height: 55px;
		text-align: center;

		/* 设置为单行文本 */
		white-space: nowrap;
		/* 设置溢出时显示省略号 */
		text-overflow: ellipsis;
		/* 设置元素宽度，并让文本在该宽度内截断 */
		/* 设置元素内容溢出部分隐藏 */
		overflow: hidden;
		/* 设置元素为块级元素，这样才能进行宽度限制 */
		display: inline-block;

	}


	.neirong {

		/* background-color: #104d7b; */
		width: 940px;
		height: 54px;
		margin-top: 0px;
		margin-left: 0px;
		float: left;
		border-bottom: #1c7bb1 solid 1px;


	}
	.zhaixuan {
		width: 135px;
		height: 61px;
		margin-top: 0px;
		margin-left: 0px;
		float: left;
		/* border: #fff solid 1px; */
	}

	.baojingleixingshaixuan {
		width: 990px;
		height: 61px;
		margin-top: 15px;
		margin-left: 47.5px;
		float: left;
		/* border: #fff solid 1px; */
	}

	.xia {
		width: 940px;
		height: 544px;
		margin-top: 0px;
		margin-left: 72.5px;
		float: left;
		/* border: #fff solid 1px; */
		overflow-x: hidden;
		overflow-y: scroll;
	}

	.fanye {
		width: 940px;
		height: 58px;
		margin-top: 0px;
		margin-left: 72.5px;
		float: left;

	}

	.block {
		height: 46px;
		margin-top: 12px;
		float: right;

	}

	.x-tc-page-bg {
		background: url("~@/assets/common/tanChuang.png") no-repeat;
		background-size: 100% 100%;
		width: 1132px;
		height: 813px;
		position: fixed;
		top: 100px;
		left: 400px;
		z-index: 99999;
	}

	.x-tc-title {
		font-size: 22px;
		position: absolute;
		left: 80px;
		top: 15px;
		color: white;
		letter-spacing: 5px;
	}

	.x-close {
		width: 50px;
		height: 50px;
		position: absolute;
		right: 30px;
		cursor: pointer;
	}


	.x-tc-cnt {
		width: 1085px;
		height: 750px;
		position: absolute;
		left: 22px;
		top: 58px;
		overflow: hidden;
	}
</style>