//监测感知 测站
// import request from '@/utils/request'
import qx from "@/assets/icon/deviceIcon/设备icon-气象站.png";
import spz from "@/assets/icon/deviceIcon/设备icon-视频站.png";
// import szz from "@/assets/icon/deviceIcon/设备icon-水质站.png";
// import wyz from "@/assets/icon/deviceIcon/设备icon-位移站.png";
// import syz from "@/assets/icon/deviceIcon/设备icon-渗压站.png";
// import sq from "@/assets/icon/deviceIcon/设备icon-墒情站.png";
// import ll from "@/assets/icon/deviceIcon/设备icon-流量站.png";
// import sw from "@/assets/icon/deviceIcon/设备icon-水位站.png";
// import ykz from "@/assets/icon/deviceIcon/设备icon-远控站.png";

// import hsl from '@/assets/icon/pointImg/icon.png'

const state = {
	// 水闸展示
	shuizhazhanshiInfo: [],
	shuizhazhanshiEntitie: [],
	shuibengzhanshiInfo: [],
	shuibengzhanshiEntitie: [],
	// 气象检测站
	QiXiangStationInfo: [],
	QiXiangStationEntitie: [],
	// 地热流量计站
	DiReStationInfo: [],
	DiReStationEntitie: [],
	//监控
	VideoStationInfo: [],
	VideoStationEntitie: [],
	//泵组检测站
	BengZuInfo: [],
	BengZuEntitie: [],
	// 渗压检测站
	osmoticPressureTotal: 0,
	osmoticPressureInfo: [],
	osmoticPressureEntitie: [],
	// 位移检测站
	displacementTotal: 0,
	displacementTInfo: [],
	displacementEntitie: [],
	// 工情检测站
	GongQingInfo: [],
	GongQingEntitie: [],
	// 土壤墒情站
	TuRangInfo: [],
	TuRangInfoEntitie: [],
	soilMoistureTotal: 0,
	// 含沙量检测站
	HanShaInfo: [],
	HanShaInfoEntitie: [],
	// 流量检测站
	LiuLiangJZInfo: [],
	LiuLiangJZInfoEntitie: [],
	// 电子界桩
	DIANZIJIEZHUANGInfo: [],
	DIANZIJIEZHUANGEntitie: [],

	flowTotal: 0,
	// 水位检测站
	ShuiWeiJZInfo: [],
	ShuiWeiJZInfoEntitie: [],
	waterLevelTotal: 0,
	// 巡检点
	xunjiandianInfo: [],
	xunjiandianInfoEntitie: [],
	xunjiandianTotal: 0,
	// 闸门监控站
	ZhaMenJKInfo: [],
	ZhaMenJKInfoEntitie: [],
	// 工业听诊
	GongYeTZInfo: [],
	GongYeTZInfoEntitie: [],
	// 水质检测站
	ShuiZhiInfo: [],
	ShuiZhiInfoEntitie: [],
	waterQualityTotal: 0,
	// 视频站
	monitorTotal: 0,
	// 远控站
	remoteControlStationTotal: 0,
	// 水电站
	hydropowerStationTotal: 0,
	EntitieList: {
		ShuiZhiInfoEntitie: []
	},
	Info: {
		ShuiZhiInfo: []
	},
}

const mutations = {
	// 现存勿动
	// 存储数据消除
	StorageTotal: (state) => {
		state.DiReStationInfo = [];
		state.QiXiangStationInfo = [];
		state.shuizhazhanshiInfo = [];
		state.shuibengzhanshiInfo = [];
		state.BengZuInfo = [];
		state.osmoticPressureInfo = [];
		state.displacementTInfo = [];
		state.GongQingInfo = [];
		state.TuRangInfo = [];
		state.HanShaInfo = [];
		state.LiuLiangJZInfo = [];
		state.DIANZIJIEZHUANGInfo = [];
		state.ShuiWeiJZInfo = [];
		state.xunjiandianInfo = [];
		state.ZhaMenJKInfo = [];
		state.GongYeTZInfo = [];
		state.ShuiZhiInfo = [];
	},
	// 气象检测站
	ADD_QiXiangStation: (state, val) => {
		state.QiXiangStationInfo.push(val);
	},
	// 水闸展示
	ADD_shuizhazhanshi: (state, val) => {
		state.shuizhazhanshiInfo.push(val);
	},
	// 水泵展示
	ADD_shuibengzhanshi: (state, val) => {
		state.shuibengzhanshiInfo.push(val);
	},
	// 地热流量计站
	ADD_DiReStation: (state, val) => {
		state.DiReStationInfo.push(val);
	},
	// 视频监视站
	ADD_VideoStation: (state, val) => {
		state.VideoStationInfo.push(val);
	},
	// 泵组检测站
	ADD_BengZu: (state, val) => {
		state.BengZuInfo.push(val);
	},
	// 渗压检测站
	ADD_OSMOTIC_PRESSURE: (state, val) => {
		state.osmoticPressureInfo.push(val);
	},
	SET_OSMOTIC_PRESSURE_TOTAL: (state, val) => {
		state.osmoticPressureTotal = val
	},
	// 位移检测站
	ADD_DISPLACEMENTT: (state, val) => {
		state.displacementTInfo.push(val);
	},
	SET_DISPLACEMENT_TOTAL: (state, val) => {
		state.displacementTotal = val
	},
	// 工情检测站
	ADD_GongQing: (state, val) => {
		state.GongQingInfo.push(val);
	},
	// 土壤墒情站
	ADD_TuRangStation: (state, val) => {
		state.TuRangInfo.push(val);
	},
	SET_SOIL_MOISTURE_TOTAL: (state, val) => {
		state.soilMoistureTotal = val
	},
	SET_WEATHER_STATION_TOTAL: (state, val) => {
		state.soilMoistureTotal = val
	},
	// 含沙量检测站
	ADD_HanShaStation: (state, val) => {
		state.HanShaInfo.push(val);
	},
	// 流量检测站
	ADD_LiuLiangStation: (state, val) => {
		state.LiuLiangJZInfo.push(val);
	},
	// 电子界桩
	ADD_DIANZIJIEZHUANGStation: (state, val) => {
		state.DIANZIJIEZHUANGInfo.push(val);
	},
	SET_FLOW_TOTAL: (state, val) => {
		state.flowTotal = val
	},
	// 水位检测站
	ADD_ShuiWeiStation: (state, val) => {
		state.ShuiWeiJZInfo.push(val);
	},
	SET_WATER_LEVEL_TOTAL: (state, val) => {
		state.waterLevelTotal = val
	},
	//巡检点
	ADD_XUNJIANDIAN: (state, val) => {
		state.xunjiandianInfo.push(val);
	},
	SET_XUNJIANDIAN: (state, val) => {
		state.xunjiandianTotal = val
	},
	// 闸门监控站
	ADD_ZhaMenJK: (state, val) => {
		state.ZhaMenJKInfo.push(val);
	},
	//工业听诊站
	ADD_GongYeTZ: (state, val) => {
		state.GongYeTZInfo.push(val);
	},
	// 水质检测站
	// ADD_ShuiZhiStation: (state, val) => {
	// 	state.Info.ShuiZhiInfo.push(val);
	// },
	ADD_ShuiZhiStation: (state, val) => {
		state.ShuiZhiInfo.push(val);
	},
	SET_WATER_QUALITY_TOTAL: (state, val) => {
		state.waterQualityTotal = val
	},
	// 视频站
	SET_MONITOR_TOTAL: (state, val) => {
		state.monitorTotal = val
	},
	// 远控站
	SET_REMOTE_CONTROL_STATION_TOTAL: (state, val) => {
		state.remoteControlStationTotal = val
	},
	// 水电站
	SET_HYDROPOWER_STATION_TOTAL: (state, val) => {
		state.hydropowerStationTotal = val
	},
}


const actions = {

	//视频监视站显示
	showVideoStationOfIds({
		commit
	}, ids) {
		for (let item of state.VideoStationEntitie) {
			viewer.entities.remove(item)
		}
		state.VideoStationEntitie = [];
		if (ids.length === 0) {
			return;
		}
		for (let item of state.VideoStationInfo) {
			if (ids.includes(item.id)) {
				let entitie = addEntitiePoint(item.name, item.longitude, item.latitude, item.img, item.name, 0.11, () => {
					window.stationClick(item);
				});
				////entitie.show = false;
				entitie.treeId = item.id;
				state.VideoStationEntitie.push(entitie)
			}
		}
	},

	// 含沙量检测站显示
	showHanShaOfIds({ commit }, ids) {
		// console.log(ids)
		// console.log(state.HanShaInfo)
		for (let item of state.HanShaInfoEntitie) {
			viewer.entities.remove(item)
		}
		state.HanShaInfoEntitie = [];
		if (ids.length === 0) {
			return;
		}
		for (let item of state.HanShaInfo) {
			if (ids.includes(item.id)) {
				let entitie = addEntitiePoint(item.name, item.longitude, item.latitude, item.img, item.name, 0.11, () => {
					window.stationClick(item)
				});
				entitie.treeId = item.id;
				//entitie.show = false;
				state.HanShaInfoEntitie.push(entitie)
			}
		}
	},


	// 气象站显示
	showQiXiangStationOfIds({
		commit
	}, ids) {
		for (let item of state.QiXiangStationEntitie) {
			// console.log("B");
			// console.log(item);
			viewer.entities.remove(item)
		}
		state.QiXiangStationEntitie = [];
		if (ids.length === 0) {
			return;
		}
		for (let item of state.QiXiangStationInfo) {
			if (ids.indexOf(item.id) !== -1) {
				let name = item.name;
				let id = item.id;
				let longitude = item.longitude;
				let latitude = item.latitude;
				let tutu = item.img
				//调用接口，展示基本信息
				/* request(
						{
							url: window.$baseUrl_hz_bz+"/station/stationinfo/"+item.stationId,
							type: 'get',
						}
				).then((val) => {
					console.log(val);
				}); */
				let html = `
				    <div class="x-popup-bg">
				    <div class="x-popup-title">${name}</div>
				    <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
				    <div class="x-popop-cnt text-sm ">
				   <div class="flex ">
				        <div>负责人员：</div>
				         <div>--</div>
				    </div>
				     <div class="flex ">
				        <div>负责人员联系方式：</div>
				         <div>--</div>
				    </div>
				       <div class="flex">
				        <div>操作：</div>
				         <div class="x-check-num cursor" onclick="jianCeGanZhiClick(0,'${item.id}')">查看详情</div>
				    </div>
				    </div>
				    </div>
				    `;

				let entitie = addEntitiePoint(name, longitude, latitude, tutu, html);
				////entitie.show = false;
				entitie.bindingId = item.id;
				state.QiXiangStationEntitie.push(entitie)
			}
		}
	},

	// 检测站共用显示事件
	showStationOfIds({ commit }, ids) {
		// console.log(state)
		for (let item of state[ids[2]]) {
			viewer.entities.remove(item)
		}
		state[ids[2]] = [];
		let scale = 0.1;
		for (let item of state[ids[1]]) {
			if (ids[0].includes(item.id)) {
				let name = item.name;
				let longitude = item.longitude;
				let latitude = item.latitude;
				let O_Comment = item.O_Comment || '';
				let entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
					window.stationClick(item)
				});
				entitie.treeId = item.id;
				//entitie.show = false;
				state[ids[2]].push(entitie)
			}
		}
	},

	//泵组检测站显示
	// showBengZuOfIds({ commit }, ids) {
	// 	for (let item of state.BengZuEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.BengZuEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.BengZuInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = item.O_Comment || '';
	// 			let entitie = addEntitiePoint(name, longitude, latitude, ykz, name + O_Comment, scale, () => {
	// 				window.stationClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.BengZuEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 渗压检测站显示
	// showSYOfIds({ commit }, ids) {
	// 	for (let item of state.osmoticPressureEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.osmoticPressureEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.osmoticPressureInfo) {
	// 		if (ids.indexOf(item.id) !== -1) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let entitie = addEntitiePoint(name, longitude, latitude, syz, item.name, scale, () => {
	// 				window.stationClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.osmoticPressureEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 位移检测站显示
	// showWYOfIds({ commit }, ids) {
	// 	for (let item of state.displacementEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.displacementEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.displacementTInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let entitie = addEntitiePoint(name, longitude, latitude, wyz, '', scale, () => {
	// 				window.stationClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.displacementEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 工情检测站显示
	// showGongQingOfIds({ commit }, ids) {
	// 	for (let item of state.GongQingEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.GongQingEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.GongQingInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = item.O_Comment || '';
	// 			let entitie = addEntitiePoint(name, longitude, latitude, ykz, name + O_Comment, scale, () => {
	// 				window.stationClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.GongQingEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 土壤墒情站显示
	// showTuRangOfIds({ commit }, ids) {
	// 	for (let item of state.TuRangInfoEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.TuRangInfoEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.TuRangInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = item.O_Comment || '';
	// 			let entitie = addEntitiePoint(name, longitude, latitude, sw, name + O_Comment, scale, () => {
	// 				window.stationClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.TuRangInfoEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 流量检测站显示
	// showLiuLiangJCOfIds({ commit }, ids) {
	// 	for (let item of state.LiuLiangJZInfoEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.LiuLiangJZInfoEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.LiuLiangJZInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = item.O_Comment || '';
	// 			let entitie = addEntitiePoint(name, longitude, latitude, sw, name + O_Comment, scale, () => {
	// 				window.stationClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.LiuLiangJZInfoEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 水位检测站显示
	// showShuiWeiJCOfIds({ commit }, ids) {
	// 	for (let item of state.ShuiWeiJZInfoEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.ShuiWeiJZInfoEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.ShuiWeiJZInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = item.O_Comment || '';
	// 			let entitie = addEntitiePoint(name, longitude, latitude, sw, name + O_Comment, scale, () => {
	// 				window.stationClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.ShuiWeiJZInfoEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 闸门监控站显示
	// showZMJKOfIds({ commit }, ids) {
	// 	for (let item of state.ZhaMenJKInfoEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.ZhaMenJKInfoEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.ZhaMenJKInfo) {
	// 		if (ids.indexOf(item.id) !== -1) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let entitie = addEntitiePoint(name, longitude, latitude, syz, item.name, scale, () => {
	// 				window.stationClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.ZhaMenJKInfoEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 水质检测站显示
	// showShuiZhiOfIds({ commit }, ids) {
	// 	for (let item of state.ShuiZhiInfoEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.ShuiZhiInfoEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.ShuiZhiInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = item.O_Comment || '';
	// 			let entitie = addEntitiePoint(name, longitude, latitude, sw, name + O_Comment, scale, () => {
	// 				window.stationClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.ShuiZhiInfoEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 视频监视站隐藏
	hiddenVideoStationInfo({ commit }, data = false) {
		for (let item of state.VideoStationEntitie) {
			item.show = data;
		}
	},

	// 气象站隐藏
	hiddenQiXiangStationInfo({ commit }, data = false) {
		for (let item of state.QiXiangStationEntitie) {
			item.show = data;
		}
	},

	// 泵组设备隐藏
	hiddenBengZuInfo({ commit }, data = false) {
		for (let item of state.BengZuEntitie) {
			item.show = data;
		}
	},

	// 渗压检测站隐藏
	hiddenSYInfo({ commit }, data = false) {
		for (let item of state.osmoticPressureEntitie) {
			item.show = data;
		}
	},

	// 位移检测站隐藏
	hiddenWYInfo({ commit }, data = false) {
		for (let item of state.displacementEntitie) {
			item.show = data;
		}
	},
	// 工情检测站隐藏
	hiddenGQInfo({ commit }, data = false) {
		for (let item of state.GongQingEntitie) {
			item.show = data;
		}
	},
	// 土壤墒情站隐藏
	hiddenTuRangInfo({ commit }, data = false) {
		for (let item of state.TuRangInfoEntitie) {
			item.show = data;
		}
	},

	// 含沙量检测站隐藏
	hiddenHanShaInfo({ commit }, data = false) {
		for (let item of state.HanShaInfoEntitie) {
			item.show = data;
		}
	},

	// 流量检测站隐藏
	hiddenLiuLiangJZInfo({ commit }, data = false) {
		for (let item of state.LiuLiangJZInfoEntitie) {
			item.show = data;
		}
	},
	// 电子界桩隐藏
	hiddenDIANZIJIEZHUANGInfo({ commit }, data = false) {
		for (let item of state.DIANZIJIEZHUANGEntitie) {
			item.show = data;
		}
	},

	// 水位检测站隐藏
	hiddenShuiWeiJZInfo({ commit }, data = false) {
		for (let item of state.ShuiWeiJZInfoEntitie) {
			item.show = data;
		}
	},
	// 水位检测站隐藏
	hiddenxunjiandianInfo({ commit }, data = false) {
		for (let item of state.xunjiandianInfoEntitie) {
			item.show = data;
		}
	},

	// 闸门监控站隐藏
	hiddenZhaMenJKInfo({ commit }, data = false) {
		for (let item of state.ZhaMenJKInfoEntitie) {
			item.show = data;
		}
	},
	// 工业听诊隐藏
	hiddenGongYeTZInfo({ commit }, data = false) {
		for (let item of state.GongYeTZInfoEntitie) {
			item.show = data;
		}
	},


	// 水质检测站隐藏
	hiddenShuiZhiJCInfo({ commit }, data = false) {
		for (let item of state.ShuiZhiInfoEntitie) {
			item.show = data;
		}
	},
	// 根据ID飞行
	flyInfoOfId({
		commit
	}, info) {
		var id = info[0];
		var infoEntitle = info[1];
		for (let item of infoEntitle) {
			if (item.treeId.toString() === id.toString()) {
				viewer.mars.flyTo(item, {
					scale: 2.5,
					radius: 2000
				});
				// item.name.show = true;
				window.setTimeout(function () {
					// item.name.show = false;
				}, 5000);
				return;
			}
		}
	},
};


export default {
	namespaced: true,
	state,
	mutations,
	actions
}


/**
 * 添加点位数据
 * @param name
 * @param lon
 * @param lat
 * @param pointImg
 * @param html
 */
function addEntitiePoint(name, lon, lat, pointImg, html, scale = 0.11, callback) {
	let entitie = viewer.entities.add({
		position: Cesium.Cartesian3.fromDegrees(lon, lat),
		show: true,
		billboard: {
			image: pointImg,
			scale: 0.29, //原始大小的缩放比例
			horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
			verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
			scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 8.0e6, 0.1),
			disableDepthTestDistance: Number.POSITIVE_INFINITY, //一直显示，不被地形等遮挡
		},
		label: {
			show: false,
			text: name,
			font: "normal small-caps normal 19px 楷体",
			style: Cesium.LabelStyle.FILL_AND_OUTLINE,
			fillColor: Cesium.Color.fromCssColorString("#ffffff").withAlpha(1),
			outlineWidth: 2,
			horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
			verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
			pixelOffset: new Cesium.Cartesian2(20, -10), //偏移量
			heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, //贴地
		},
		tooltip: {
			html: name,
			anchor: [0, -10],
		},
		/* popup: {
			html: html,
			anchor: [0, -12], //左右、上下的偏移像素值。
			onAdd: function(eleId) {
				window._popup_eleId = eleId;
			},
		}, */
		click: function (e) { //单击后的回调
		
			console.log("+++++++++station.js++++++++")
			callback();
		}
	});
	return entitie;
}